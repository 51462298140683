import * as React from "react"
import Layout from "../components/layout"
import {Formik, Field, Form, useField} from 'formik'
import * as Yup from 'yup';
import * as contactFormStyles from './contact.module.css'
import {Helmet} from "react-helmet";

const encode = (data) => {
    return Object.keys(data)
        .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
        .join('&')
}

const MyTextInput = ({label, ...props}) => {
    // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
    // which we can spread on <input> and also replace ErrorMessage entirely.
    const [field, meta] = useField(props);
    return (
        <div className={contactFormStyles.input_cont}>
            <label className={contactFormStyles.label} htmlFor={props.id || props.name}>{label}</label>
            <input
                className={`${contactFormStyles.text} ${props.name === 'message' ? contactFormStyles.message : null}`} {...field} {...props} />
            {meta.touched && meta.error ? (
                <div className={contactFormStyles.error}>{meta.error}</div>
            ) : null}
        </div>
    );
};

const MyTextArea = ({label, ...props}) => {
    // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
    // which we can spread on <input> and also replace ErrorMessage entirely.
    const [field, meta] = useField(props);
    return (
        <div className={contactFormStyles.input_cont}>
            <label className={contactFormStyles.label} htmlFor={props.id || props.name}>{label}</label>
            <textarea
                className={`${contactFormStyles.text} ${props.name === 'message' ? contactFormStyles.message : null}`} {...field} {...props}></textarea>
            {meta.touched && meta.error ? (
                <div className={contactFormStyles.error}>{meta.error}</div>
            ) : null}
        </div>
    );
};

// markup
const Contact = () => {
    return (
        <>
            <Helmet htmlAttributes={{lang: 'en-uk'}}>
                <meta charSet="utf-8"/>
                <title>Alan Macdonald | Contact</title>
                <meta name="description" content="Contact Alan Macdonald."/>
                <meta name="keywords" content="Alan Macdonald, Contact"/>
                <meta name="author" content="Alan Macdonald"/>
                <meta charSet="utf-8"/>
                <link rel="canonical" href=""/>
                <meta property="og:title" content='Alan Macdonald'/>
                <meta property="og:description" content="Contact Alan Macdonald."/>
            </Helmet>
            <Layout>
                <main style={{}}>
                    <title>Contact</title>
                    <div className={contactFormStyles.form_cont}>
                        <h2 style={{marginBottom: "4rem", marginTop: "2rem"}}>Contact</h2>

                        <Formik
                            initialValues={{name: '', email: '', message: ''}}
                            onSubmit={(values, {setSubmitting, resetForm}) => {
                                fetch("/?no-cache=1", {
                                    method: 'POST',
                                    headers: {'Content-Type': 'application/x-www-form-urlencoded'},
                                    body: encode({
                                        'form-name': 'contact',
                                        ...values,
                                    }),
                                })
                                    .then(() => {
                                        alert('Success.')
                                        resetForm({values: ''});
                                        setSubmitting(false)
                                    })
                                    .catch(error => {
                                        alert('Error: Please Try Again.')
                                        setSubmitting(false)
                                    })
                            }}
                            validationSchema={Yup.object().shape({
                                name: Yup.string()
                                    .min(2, 'Too Short.')
                                    .max(50, 'Too Long.')
                                    .required('Name is Required.'),
                                email: Yup.string()
                                    .email('Enter a Valid Email.')
                                    .required('Email is Required.'),
                                message: Yup.string()
                                    .required('Message is Required.'),
                            })}>
                            <Form name="contact" data-netlify={true}>
                                <MyTextInput
                                    label="Full Name"
                                    name="name"
                                    type="text"
                                    placeholder=""
                                />
                                <MyTextInput
                                    label="Email Address"
                                    name="email"
                                    type="email"
                                    placeholder=""
                                />
                                <MyTextArea
                                    label="Message"
                                    name="message"
                                    type="message"
                                    placeholder=""
                                />

                                <button className={contactFormStyles.submit} type="submit">SUBMIT</button>
                            </Form>
                        </Formik>
                    </div>
                </main>
            </Layout>
        </>

    )
}

export default Contact
